import 'translations';

import * as React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ConfigProvider} from 'antd';
import Routes from 'routes';
import appTheme from 'theme/theme';

import {ScrollToTop} from 'components';

import {AuthContextProvider} from 'contexts/AuthContext';

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: 30 * 1000,
        retry: process.env.NODE_ENV !== 'development',
      },
      mutations: {
        retry: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <ConfigProvider {...appTheme}>
          <ScrollToTop />
          <Routes />
        </ConfigProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
