import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath, useHistory} from 'react-router';
import {
  Avatar,
  Button,
  Col,
  Flex,
  Layout,
  Menu,
  notification,
  Row,
  theme,
  Typography,
} from 'antd';
import {
  bottomNavigationItems,
  MenuItem,
  topNavigationItems,
} from 'routes/navigationConfig';

import {NavigationItemInterface} from 'interfaces/navigationItem.interface';

import BaseService from 'services/api/BaseService';

import {useAuthContext} from 'contexts/AuthContext';

import {ReactComponent as GrayLeavesDecor} from 'assets/decor/GrayLeaves.svg';
import {ReactComponent as GreenPlantDecor} from 'assets/decor/GreenPlant.svg';
import {ReactComponent as WhiteLogo} from 'assets/icons/WhiteLogo.svg';

const {Header, Content, Footer} = Layout;

function getNavigationKeys(items: any[]): string[] {
  return (
    items?.reduce(
      (otherItems: any[], item: any) => [
        ...otherItems,
        item.key,
        ...getNavigationKeys(item?.children),
      ],
      [],
    ) || []
  );
}

const FooterList = (props: { items: React.ReactNode[] }) => {
  const {items} = props;
  return (
    <Col xs={24} md={12} lg={6}>
      <ul>
        {items.map((item: React.ReactNode, index) => 
          <li key={`item${ index }`}>{item}</li>
        )}
      </ul>
    </Col>
  );
};

const FooterLinkList = (props: { items: any[] }) => {
  const {items} = props;
  return (
    <FooterList
      items={items.map(({key, label, target = '_self'}) => 
        <a key={key} href={key} target={target}>
          {label}
        </a>
      )}
    />
  );
};

const AppLayout = (props: { children: React.ReactNode }) => {
  const {children} = props;
  const {auth, logout} = useAuthContext();
  const {t} = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;
  const {owner} = useAuthContext();

  // Coordonnées du propriétaire du contact
  const ownerEmail: string | undefined = owner?.[0]?.Owner?.Email;
  const ownerTel: string | undefined = owner?.[0]?.Owner?.MobilePhone;
  const displayName = `${ auth?.user?.firstname } ${ auth?.user?.lastname }`;
  const initials = displayName
    .replace(/[%s-]+/, ' ')
    .split(' ')
    .map((part) => part[0])
    .join('');

  const handleLogout = useCallback(() => {
    logout();
    history.push('/connexion');
  }, [history, logout]);

  const handleMenuClick = useCallback(
    (e: MenuItem) => {
      if (e?.key === 'logout') {
        handleLogout();
      } else if (e?.key === 'notifications') {
        // TODO: Ouvrir les notifications
      } else if (e?.key === 'contact') {
        // TODO: Ouvrir la tooltip de contact
      } else if (typeof e?.key === 'string') {
        history.push(e?.key);
      }
    },
    [history, handleLogout],
  );

  const visibilityChange = () => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      BaseService.checkToken();
    }
  };

  const handleTokenExpired = useCallback(() => {
    handleLogout();
    notification.info({
      message: t('global:session_expired'),
    });
  }, [handleLogout, t]);

  useEffect(() => {
    window.addEventListener('onTokenExpired', handleTokenExpired);
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      window.removeEventListener('onTokenExpired', handleTokenExpired);
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [handleTokenExpired]);

  const navMenuItems = useMemo(
    () =>
      topNavigationItems.map((item: NavigationItemInterface) => ({
        ...item,
        icon:
          item.icon && 
            <Flex
              style={{width: 20, height: 20, verticalAlign: 'middle'}}
              justify={'center'}
            >
              {item.icon}
            </Flex>
           ||
          item.key === 'userAccount' && 
            <Avatar
              style={{
                width: 32,
                height: 32,
                margin: -6,
              }}
            >
              {initials}
            </Avatar>
        ,
        onClick: handleMenuClick,
      })),
    [handleMenuClick, initials],
  );

  const defaultSelectedKeys =
    getNavigationKeys(topNavigationItems).find((key) =>
      matchPath(pathname, {
        path: `${ key }${ key === '/' ? '' : '*' }`,
        exact: true,
      }),
    ) ?? '';

  const {
    token: {
      Layout: {headerHeight, maxWidth},
    },
  } = theme.useToken() as any;

  const impersonateHeight: number = auth?.user?.impersonated ? 52 : 0;
  const contentStyle: React.CSSProperties = {
    marginTop: headerHeight + impersonateHeight + 56,
  };
  return (
    <Layout
      className={'mx-auto min-h-dvh'}
      style={{
        minHeight: '100vh',
      }}
    >
      {impersonateHeight &&
        <div
          className={
            'fixed top-0 left-0 right-0 bg-gray-800 flex items-center justify-center gap-6'
          }
          style={{height: impersonateHeight}}
        >
          <Typography.Paragraph className={'!m-0 text-white'}>
            {t('login:impersonate', {
              name: displayName,
            })}
          </Typography.Paragraph>
          <Button onClick={handleLogout} type={'primary'}>
            {t('global:logout')}
          </Button>
        </div>
      }
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          width: '100%',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth,
            margin: 'auto',
          }}
        >
          <WhiteLogo
            style={{
              marginRight: '50px',
            }}
          />
          <Menu
            theme="dark"
            selectedKeys={[defaultSelectedKeys]}
            mode="horizontal"
            items={navMenuItems}
            style={{
              flex: 1,
              minWidth: 0,
              justifyContent: 'flex-end',
              flexGrow: 1,
            }}
          />
        </div>
      </Header>
      <Content
        style={contentStyle}
        className={' mx-auto pb-24 w-[calc(100%-172px)]'}
      >
        {children}
      </Content>
      <Footer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth,
            margin: 'auto',
            position: 'relative',
          }}
        >
          <div
            className="flex"
            style={{
              position: 'absolute',
              top: '-123px',
              right: 0,
            }}
          >
            <GreenPlantDecor style={{verticalAlign: 'middle'}} />
            <GrayLeavesDecor style={{verticalAlign: 'middle'}} />
          </div>
          <Row justify="space-between" style={{width: '100%'}} gutter={16}>
            <FooterList items={[]} />
            <FooterList
              items={[
                <>Contact&nbsp;:&nbsp;</>,
                <>
                  {ownerEmail && 
                    <a href={`mailto:${ ownerEmail }`}>{ownerEmail}</a>
                  }
                </>,
                <>{ownerTel && <a href={`tel:${ ownerTel }`}>{ownerTel}</a>}</>,
              ]}
            />
            <FooterLinkList
              items={bottomNavigationItems.slice(
                0,
                Math.ceil(bottomNavigationItems.length / 2),
              )}
            />
            <FooterLinkList
              items={bottomNavigationItems.slice(
                Math.ceil(bottomNavigationItems.length / 2),
              )}
            />
          </Row>
        </div>
      </Footer>
    </Layout>
  );
};

export default React.memo(AppLayout);
