import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery, UseQueryResult} from 'react-query';
import {useHistory} from 'react-router';
import {message} from 'antd';

import AuthService from 'services/api/AuthService';
import BaseService from 'services/api/BaseService';
import {UserUrls} from 'services/api/urls';

import {GetSFUserResponse} from 'types/User';
import {UserInfos} from 'types/UserInfos';

import {useAuthContext} from 'contexts/AuthContext';

export const usePasswordLoginUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();
  const {login} = useAuthContext();
  const {t} = useTranslation();

  const mutation = async (email: string, password: string) => {
    setLoading(true);
    return AuthService.password_login({
      email,
      password,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          login({
            user: data.user,
            token: data.jwt,
          });
          history.push('/');
        } else {
          setError(data.message || t('login:login_error'));
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loading,
    error,
    mutation,
  };
};

export const useImpersonate = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {login} = useAuthContext();
  const {t} = useTranslation();

  const mutation = async (token: string) => {
    setLoading(true);
    return AuthService.impersonate(token)
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          login({
            user: {
              ...data.user,
              impersonated: true,
            },
            token: data.jwt,
          });
          history.push('/');
        } else {
          history.push('/connexion');
          message.error(data.message || t('login:token_error'));
        }
      })
      .catch(() => {
        history.push('/connexion');
        message.error(t('global:internet_connexion_error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loading,
    mutation,
  };
};

export const usePasswordMutation = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {t} = useTranslation();

  const init = async (email: string) => {
    setLoading(true);
    return AuthService.reset_password_init(email)
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
        } else {
          setError(
            data.message ?? data.error.message ?? t('reset_password:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = async (newPassword: string, email: string, token: string) => {
    setLoading(true);
    return AuthService.reset_password({
      newPassword,
      email,
      token,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
        } else {
          setError(
            data.message ?? data.error.message ?? t('reset_password:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const create = async (newPassword: string, token: string, email: string) => {
    setLoading(true);
    return AuthService.create_password({
      newPassword,
      token,
      email,
    })
      .then(async (res: any) => {
        const data = await res.json();
        if (!data?.error && (res.status === 200 || res.status === 201)) {
          setSuccess(true);
        } else {
          setError(
            data.message ?? data.error.message ?? t('first_login:error'),
          );
        }
      })
      .catch(() => {
        setError(t('global:internet_connexion_error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    success,
    init,
    reset,
    create,
  };
};

export const useUserInfos = () => {
  const {t} = useTranslation();

  return useQuery<UserInfos, Error>(UserUrls.GET_ACCOUNT_INFO, async () => {
    let data: any;
    try {
      const response = await BaseService.getRequest(
        UserUrls.GET_ACCOUNT_INFO,
        true,
      );
      data = await response.json();
      if ([200, 201].includes(response.status)) {
        return data;
      }
    } catch (e: any) {
      console.warn('Get account info error', e);
      throw new Error(t('global:internet_connexion_error'));
    }
    throw new Error(data?.message || t('global:internet_connexion_error'));
  });
};

export const useUserSFOwner: () => UseQueryResult<
GetSFUserResponse[],
Error
> = (): UseQueryResult<GetSFUserResponse[], Error> => {
  const {t} = useTranslation();

  return useQuery<GetSFUserResponse[], Error>(
    UserUrls.GET_ACCOUNT_SF_OWNER_INFO,
    async () => {
      try {
        const response: Response = await BaseService.getRequest(
          UserUrls.GET_ACCOUNT_SF_OWNER_INFO,
          true,
        );
        if ([200, 201].includes(response.status)) {
          const data: GetSFUserResponse[] = await response.json();
          return data;
        } else {
          throw new Error(t('global:internet_connexion_error'));
        }
      } catch (e: any) {
        console.warn('Get account info error', e);
        throw new Error(t('global:internet_connexion_error'));
      }
    },
  );
};
