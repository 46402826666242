import 'index.css';
import 'overrides.scss';

import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {Router} from 'react-router';
import App from 'App';
import {createBrowserHistory} from 'history';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const history = createBrowserHistory();

root.render(
  <Router history={history}>
    <App />
  </Router>,
);
