import React, {Fragment, lazy, Suspense} from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AppLayout, AuthLayout} from 'layouts';
import {AppGuard, AuthGuard} from 'routes/guards';

import {LoadingScreen} from 'components';

export const routesConfig: RouteConfig[] = [
  {
    path: '/errors/error-404',
    exact: true,
    component: lazy(() => import('views/errors/404.page')),
    layout: Fragment,
    guard: Fragment,
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('views/home/Home.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/calculer-une-prime',
    exact: true,
    component: lazy(
      () => import('views/calculer-une-prime/CalculerUnePrime.page'),
    ),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/reinitialiser-mot-de-passe',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/creation-compte',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/connexion',
    exact: true,
    component: lazy(() => import('views/auth/Login.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/impersonate',
    exact: true,
    component: lazy(() => import('views/auth/Impersonate.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/mot-de-passe-oublie',
    exact: true,
    component: lazy(() => import('views/auth/ResetPasswordInit.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/mon-compte',
    exact: true,
    component: lazy(() => import('views/mon-compte/monCompte.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/tableau-de-bord',
    exact: true,
    component: lazy(() => import('views/not-implemented/NotImplemented.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/faq',
    exact: true,
    component: lazy(() => import('views/faq/FAQ.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/mentions-legales',
    exact: true,
    component: lazy(() => import('views/not-implemented/NotImplemented.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/conditions-generales',
    exact: true,
    component: lazy(() => import('views/not-implemented/NotImplemented.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/politique-de-confidentialite',
    exact: true,
    component: lazy(() => import('views/not-implemented/NotImplemented.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '*',
    component: () => <Redirect to="/errors/error-404" />,
    layout: Fragment,
    guard: Fragment,
  },
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? 
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route: RouteConfig, i: number) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component: any = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => 
                <Guard>
                  <Layout className="min-h-screen">
                    {route.routes ? 
                      renderRoutes(route.routes)
                      : 
                      <Component {...props} />
                    }
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Switch>
    </Suspense>
    : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
