import React from 'react';
import {Redirect} from 'react-router-dom';

import {useAuthContext} from 'contexts/AuthContext';

function AuthGuard(props: any) {
  const {children} = props;

  const {auth} = useAuthContext();
  if (auth?.token) {
    return <Redirect to="/" />;
  }

  return children;
}

export default AuthGuard;
